import Header from "../components/header/Header";
import SocialProgram from "../components/socialProgram/SocialProgram";


function SocialProgramPage() {
    return (
        <div className="App">
            <Header />
            <SocialProgram />
        </div>
    )
}

export default SocialProgramPage;